import type { CheckboxProps } from './ui/checkbox'

import React, { useId } from 'react'
import { useInputControl } from '@conform-to/react'

import { cn } from '~/utils/misc'
import { Checkbox } from './ui/checkbox'
import { Input } from './ui/input'
import { Label } from './ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select'
import { Textarea } from './ui/textarea'

export type ListOfErrors = Array<string | null | undefined> | null | undefined

export function ErrorList({
  id,
  errors,
  className,
}: {
  errors?: ListOfErrors
  id?: string
  className?: string
}) {
  const errorsToRender = errors?.filter(Boolean)
  if (!errorsToRender?.length) return null
  return (
    <ul id={id} className={cn('flex flex-col gap-1', className)}>
      {errorsToRender.map((e) => (
        <li key={e} className="text-sm text-destructive">
          {e}
        </li>
      ))}
    </ul>
  )
}

export function Field({
  labelProps,
  inputProps,
  errors,
  className,
  description,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  inputProps: React.InputHTMLAttributes<HTMLInputElement>
  errors?: ListOfErrors
  className?: string
  description?: string
}) {
  const fallbackId = useId()
  const id = inputProps.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined
  return (
    <div className={className}>
      <Label htmlFor={id} {...labelProps} />
      <div className="mt-2">
        <Input
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          {...inputProps}
        />
      </div>
      <div className="mt-1 px-3 pb-3 text-sm empty:hidden">
        {errorId ? (
          <ErrorList id={errorId} errors={errors} />
        ) : description ? (
          <p className="text-muted-foreground">{description}</p>
        ) : null}
      </div>
    </div>
  )
}

export function TextareaField({
  labelProps,
  textareaProps,
  errors,
  className,
  description,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  textareaProps: React.TextareaHTMLAttributes<HTMLTextAreaElement>
  errors?: ListOfErrors
  className?: string
  description?: string
}) {
  const fallbackId = useId()
  const id = textareaProps.id ?? textareaProps.name ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined
  return (
    <div className={className}>
      <Label htmlFor={id} {...labelProps} />
      <div className="mt-2">
        <Textarea
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          {...textareaProps}
        />
      </div>
      <div className="min-h-[32px] px-2 pb-3 pt-1 empty:hidden">
        {errorId ? (
          <ErrorList id={errorId} errors={errors} />
        ) : description ? (
          <p className="text-body-xs text-muted-foreground">{description}</p>
        ) : null}
      </div>
    </div>
  )
}

export function CheckboxField({
  labelProps,
  buttonProps,
  errors,
  className,
}: {
  labelProps: JSX.IntrinsicElements['label']
  buttonProps: CheckboxProps & {
    name: string
    form: string
    value?: string
  }
  errors?: ListOfErrors
  className?: string
}) {
  const { key, defaultChecked, ...checkboxProps } = buttonProps
  const fallbackId = useId()

  const checkedValue = buttonProps.value ?? 'on'
  const input = useInputControl({
    key,
    name: buttonProps.name,
    formId: buttonProps.form,
    initialValue: defaultChecked ? checkedValue : undefined,
  })

  const id = buttonProps.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  return (
    <div className={className}>
      <div className="flex gap-2">
        <Checkbox
          {...checkboxProps}
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          checked={input.value === checkedValue}
          onCheckedChange={(state) => {
            input.change(state.valueOf() ? checkedValue : '')
            buttonProps.onCheckedChange?.(state)
          }}
          onFocus={(event) => {
            input.focus()
            buttonProps.onFocus?.(event)
          }}
          onBlur={(event) => {
            input.blur()
            buttonProps.onBlur?.(event)
          }}
          type="button"
        />
        <label
          htmlFor={id}
          {...labelProps}
          className="cursor-pointer self-center text-body-xs text-muted-foreground"
        />
      </div>
      <div className="px-2 pb-3 pt-1 empty:hidden">
        {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
      </div>
    </div>
  )
}

export function SelectField({
  labelProps,
  selectProps,
  errors,
  className,
  description,
  options,
  placeholder,
}: {
  labelProps: React.LabelHTMLAttributes<HTMLLabelElement>
  selectProps: React.SelectHTMLAttributes<HTMLSelectElement> & {
    defaultValue?: string
  }
  errors?: ListOfErrors
  className?: string
  description?: string
  options: Array<{ value: string; children: React.ReactNode }>
  placeholder: string
}) {
  const fallbackId = useId()
  const id = selectProps.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined
  return (
    <div className={className}>
      <Label htmlFor={id} {...labelProps} />
      <div className="mt-2">
        <Select
          autoComplete={selectProps.autoComplete}
          defaultValue={selectProps.defaultValue}
          disabled={selectProps.disabled}
          name={selectProps.name}
          required={selectProps.required}
        >
          <SelectTrigger id={id} aria-invalid={selectProps['aria-invalid']}>
            <SelectValue placeholder={placeholder} />
          </SelectTrigger>
          <SelectContent>
            {options.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.children}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <div className="mt-1 px-3 pb-3 text-sm empty:hidden">
        {errorId ? (
          <ErrorList id={errorId} errors={errors} />
        ) : description ? (
          <p className="text-muted-foreground">{description}</p>
        ) : null}
      </div>
    </div>
  )
}
